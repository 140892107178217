<template>
  <div id="newsStoryContent">
    <p id="Text1">
      Last week in Los Angeles, LEGO<span style="FONT-SIZE: 11pt; FONT-FAMILY: 'Calibri','sans-serif'; mso-ascii-theme-font: minor-latin; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-hansi-theme-font: minor-latin; mso-bidi-font-family: 'Times New Roman'; mso-bidi-theme-font: minor-bidi; mso-ansi-language: EN-US; mso-fareast-language: EN-US; mso-bidi-language: AR-SA">®</span>
      Universe took E3 by <strike>storm</strike> Maelstrom, capturing the eyes and imaginations of show attendees and members of the press. Not only did LEGO Universe meet all the expectations of a massively LEGO online game – it shattered them, blowing away demo players left and right with building, battling, customisation, exploration and good old-fashioned fun.
      <br>
      <br>
    </p>
    <p align="center">
      <!-- pic78CA0296-A372-44DA-A40E-C4D75C8F50FC.jpg -->
      <img alt="" src="" class="rounded" style="background: #000; display: block; width: 440px; height: 176px;">
    </p>
    <br>
    There was certainly some stiff competition in the MMOG (massively multiplayer online game) genre at E3 this year, but that didn’t stop our development team from bringing home some of the show’s top honors. LEGO Universe beat out big names like <i>Final Fantasy XIV</i>
    and <i>Star Wars: The Old Republic</i> to win multiple E3 awards, proving to gamers everywhere that LEGO Universe is a (Nexus) Force to be reckoned with – not just for kids, but for LEGO fans and gamers of all ages.
    <br>
    <br>
    Don’t just take our word for it – check out some of the incredible E3 awards bestowed upon LEGO Universe by the press so far! <br>
    <br>
    <b><u>
      <!-- pic6766FB0C-EAF3-4C62-99D6-92C578F51C4B.jpg -->
      <img alt="" src="" class="rounded" style="background: #000; display: block; width: 175px; height: 135px; margin: 5px 10px">
      GamePro</u></b> – <b>Best of E3:</b>
    “Rather than break things down by genre, platform, or category we wanted to come up with a short list of products that really wowed us. Products that we feel that you really need to see and experience in the coming months… Don't dismiss this as a "kids" MMO. <i>LEGO Universe</i>
    is an impressive online game, but also benefits from an easy-to-understand build mode that features every kind of LEGO brick ever made. You can even program your creations to respond and react to in-game behavior too.”<br>
    <br>
    <br>
    <b><u>Boys’ Life</u></b> – <b>Best Kids MMO Ever:</b>
    “Speaking of deep, this is one deep game. You can make any world you want to with LEGO blocks, and every block ever made is in the game. It’s going to be updated every month with new quests and minigames. You won’t want to leave this game, but you probably should eventually, especially if you have homework to do.”
    <br>
    <br>
    <br>
    <b><u>
      <!-- picED010ACE-9B47-4CEE-B8E8-406F950537B4.jpg -->
      <img alt="" src="" class="rounded" style="background: #000; display: block; width: 130px; margin: 15px 10px; height: 151px;">
      Massively</u> - MMO of the Show:
    </b>
    “Not only did we think LEGO Universe was the best new MMO at E3, but even among its already-released peers, it has proven itself a star. A star made of little yellow LEGO bricks, but still a star. This was one where word quickly spread among those <i>Massively</i>
    staff members in attendance that making time for <i>LEGO Universe</i> should be a priority. Many of us tried it out and we all loved it. It's fun, it's creative and it's exactly what any LEGO fan would want in an MMO. Heck, it's exactly what any MMO fan would want in an MMO.”
    <br>
    <br>
    <br>
    <b><u>
      <!-- pic02035239-C3D5-43B6-99B2-3F5BFCDF2604.jpg -->
      <img alt="" src="" class="rounded" style="background: #000; display: block; width: 130px; margin: 5px 10px; height: 151px;">
      Massively </u> - Best New MMO:
    </b>
    “As an overall experience, <i>LEGO Universe</i> offers more than anything else we saw at E3. Among new and upcoming games, it especially shown through. I could sit here and try to explain it all in this small paragraph, but you'll have to read our write-up for that info.”
    <br>
    <br>
    <b><u><br>1UP </u> - Best MMO Game of E3 2010:</b>
    “Surprised? Don't be. Though <i>Star Wars: The Old Republic</i> certainly took the cake for role-playing elements, <i>Lego Universe</i>
    wowed us with its in-game building features. Not only can players interact with the whimsical Lego world fashioned by the developers, but they can also claim empty properties on which to build their own blocky creations, including creatures complete with customizable A.I., which sets this MMO apart from any other on the list.”<br>
    <br>
    <b>IGN Voodoo Extreme Reader’s Choice Awards E3 2010:</b>
    LEGO Universe has also been nominated in 3 categories: “Best Overall PC Game,” “Best PC MMO or Online Game” and “Best PC Trailer” and we need your help to win! Vote in the reader polls on VE3D now, and spread the word to your friends.<br>
    <br>
    Keep an eye out for more honours and awards to come, but the REAL prize is in the gameplay, so make sure to
    <a href="/campaign/preorder">pre-order</a>
    your copy in time for the LEGO Universe launch in October.
  </div>
</template>
